<!-- 页尾 -->
<template>
    <div class="footer">
        <div class="footer-c color-666 font-12">
            <p>互联网药品信息服务资格证书编号:(湘)--2020-0036</p>
            <p class="mt-5">版权所有 湖南湘东红润医药有限公司 2009-2021 湘ICP备20006033号</p>
            <p class="mt-5">
                <span>技术支持：湖南仁捷信息科技有限公司</span>
                <span class="ml-20">电话：187-1112-3379</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components: {},

    data() {
        return {
            
        };
    },

    created() {
        
    },

    methods: {

    }
}
</script>
<style lang='scss' scoped>
    .footer{
        padding: 20px 0;
        background-color: #DEDEDE;
        .footer-c{
            width: 1200px;
            margin: 0 auto;
            text-align: center;
        }
    }
</style>